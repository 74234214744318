<template>
  <div class="section" id="section0">
    <!-- borders start -->
    <div class="borders-l"></div>
    <div class="borders-r"></div>
    <!-- borders end -->
    <!-- home bg start -->
    <div class="section-bg-home overlay overlay-inverse-dark-45"></div>
    <!-- home bg end -->
    <!-- intro wrapper start -->
    <div class="intro-wrapper">
      <!-- intro start -->
      <div class="intro">
        <!-- intro subtitle start -->
        <!-- <div class="intro-subtitle">
                            УПРАВЛЯЙ ЭНЕРГИЕЙ!
                        </div> -->
        <!-- intro subtitle end -->
        <!-- divider start -->
        <div class="inner-divider-half"></div>
        <!-- divider end -->
        <!-- intro title start -->
        <div id="intro-title-lead">{{ $t('energy') }}</div>
        <!-- intro title end -->
        <!-- divider start -->
        <div class="inner-divider-half"></div>
        <!-- divider end -->
        <!-- intro title start -->
        <!-- <div id="intro-title">
                            Grandex
                        </div> -->
        <!-- intro title end -->
      </div>
      <!-- intro end -->
    </div>
    <!-- intro wrapper end -->
    <!-- bottom credits start -->
    <div class="bottom-credits">
      <a href="#">NITRO</a> &copy; {{ $t('copyright') }}
    </div>
    <!-- bottom credits end -->
    <!-- social icons start -->
    <div class="social-icons-wrapper">
      <ul class="social-icons">
        <li>
          <a
            class="ion-social-facebook"
            href="https://www.facebook.com/nitro.kg/"
            target="_blank"
          ></a>
        </li>
        <li>
          <a href="https://vm.tiktok.com/ZSe4bvgyA/" target="_blank"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              style="width: 25px; height: 25px"
              viewBox="0 0 512 512">
              <title>Logo Tiktok</title>
              <path
                fill="#ffffff"
                d="M412.19 118.66a109.27 109.27 0 01-9.45-5.5 132.87 132.87 0 01-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 01-35.22 55.56 68.8 68.8 0 01-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0121.41 3.39l.1-83.94a153.14 153.14 0 00-118 34.52 161.79 161.79 0 00-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 00115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0027.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61z"
              /></svg></a>
        </li>
        <li>
          <a
            class="ion-social-instagram"
            href="https://www.instagram.com/nitro.kgz/"
            target="_blank"
          ></a>
        </li>
      </ul>
    </div>
    <!-- social icons end -->
    <!-- scroll indicator start -->
    <div class="scroll-indicator">
      <div class="scroll-indicator-wrapper">
        <div class="scroll-line"></div>
      </div>
    </div>
    <!-- scroll indicator end -->
  </div>
</template>
