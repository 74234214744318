<template>
  <div class="section light-mode" id="section1">
    <!-- borders start -->
    <div class="borders-l borders-dark"></div>
    <div class="borders-r borders-dark"></div>
    <!-- borders end -->
    <!-- section title start -->
    <div class="section-title-wrapper">
      <!-- section subtitle start -->
      <h3 class="section-heading"><span>01.</span> {{ $t('nav.home') }}</h3>
      <!-- section subtitle end -->
    </div>
    <!-- section title end -->
    <!-- section inner start -->
    <div class="section-inner">
      <!-- container start -->
      <div class="container-fluid sections">
        <!-- row start -->
        <div class="row">
          <!-- col start -->
          <div class="col-md-12 col-sm-12 nopadding">
            <!-- slick about start -->
            <div class="slick-about">
              <!-- col start -->
              <div class="col-md-12 col-sm-12 nopadding">
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- about content wrapper start -->
                  <div class="overlay overlay-inverse-dark-35">
                    <!-- about IMG start -->
                    <div class="about-bg-wrapper">
                      <div class="about-bg about-bg-1"></div>
                    </div>
                    <!-- about IMG end -->
                  </div>
                  <!-- about content wrapper end -->
                </div>
                <!-- col end -->
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- about content wrapper start -->
                  <div class="all-content-wrapper">
                    <div class="all-content">
                      <div class="all-content-inner">
                        <!-- section subtitle start -->
                        <h2 class="section-subheading section-subheading-light">
                          <span>Nitro Energy</span>
                        </h2>
                        <!-- section subtitle end -->
                        <!-- divider start -->
                        <div class="inner-divider-half"></div>
                        <!-- divider end -->
                        <!-- section title start -->
                        <h2 class="section-heading section-heading-light">
                          {{ $t('nav.about-us') }}
                        </h2>
                        <!-- section title end -->
                        <!-- divider start -->
                        <div class="inner-divider"></div>
                        <!-- divider end -->
                        <!-- TXT start -->
                        <div class="all-txt scroll-text" v-html="$t('about-text')">
                        </div>
                        <!-- TXT end -->
                        
                        <div class="inner-divider-half"></div>
                      </div>
                    </div>
                  </div>
                  <!-- about content wrapper end -->
                </div>
                <!-- col end -->
              </div>
              <!-- col end -->
              <!-- col start -->
              <div class="col-md-12 col-sm-12 nopadding">
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- about content wrapper start -->
                  <div class="overlay overlay-inverse-dark-35">
                    <!-- about IMG start -->
                    <div class="about-bg-wrapper">
                      <div class="about-bg about-bg-2"></div>
                    </div>
                    <!-- about IMG end -->
                  </div>
                  <!-- about content wrapper end -->
                </div>
                <!-- col end -->
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- about content wrapper start -->
                  <div class="all-content-wrapper">
                    <div class="all-content">
                      <div class="all-content-inner">
                        <!-- section subtitle start -->
                        <h2 class="section-subheading section-subheading-light">
                          <span>{{ $t('in-assortment') }}</span>
                        </h2>
                        <!-- section subtitle end -->
                        <!-- divider start -->
                        <div class="inner-divider-half"></div>
                        <!-- divider end -->
                        <!-- section title start -->
                        <h2 class="section-heading section-heading-light">
                          {{ $t('products') }}
                        </h2>
                        <!-- section title end -->
                        <!-- divider start -->
                        <div class="inner-divider"></div>
                        <!-- divider end -->
                        <!-- TXT start -->
                        <div class="all-txt">
                          <p v-html="$t('juicedList')"></p>
                          <p v-html="$t('exoticList')"></p>
                          <p v-html="$t('classicList')"></p>
                        </div>
                        <!-- TXT end -->
                        <!-- divider start -->
                        <div class="inner-divider-half"></div>
                        <!-- divider end -->
                        <!-- button start -->
                        <!-- <div class="the-button-wrapper">
                                <a class="the-button" href="#">
                                <span>Коммерческое предложение</span>                                                            </a>                                                        
                            </div> -->
                        <!-- button end -->
                      </div>
                    </div>
                  </div>
                  <!-- about content wrapper end -->
                </div>
                <!-- col end -->
              </div>
              <!-- col end -->
            </div>
            <!-- slick about end -->
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
      <!-- container end -->
    </div>
    <!-- section inner end -->
    <!-- slick nav start -->
    <div class="slick-nav-custom-about"></div>
    <!-- slick nav end -->
  </div>
</template>
