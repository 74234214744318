<template>
  <div class="section light-mode" id="section2">
    <!-- borders start -->
    <div class="borders-l borders-dark"></div>
    <div class="borders-r borders-dark"></div>
    <!-- borders end -->
    <!-- section title start -->
    <div class="section-title-wrapper">
      <!-- section subtitle start -->
      <h3 class="section-heading"><span>02.</span> {{ $t('products') }}</h3>
      <!-- section subtitle end -->
    </div>
    <!-- section title end -->
    <!-- works IMG carousel start -->
    <div class="works-page-img-wrapper">
      <!-- works IMG OWL carousel start -->
      <div class="owl-carousel" id="works-page-img-carousel">
        <!-- works IMG carousel item 3 start -->
        <div
          class="item-photo works-page-img-carousel-item works-page-img-carousel-item-3 overlay overlay-inverse-dark-45"
        >
          <!-- works content wrapper 3 start -->
          <div class="news-page-img-carousel-wrapper">
            <div class="news-page-img-carousel-text">
              <!-- divider start -->
              <div class="inner-divider-half"></div>
              <!-- divider end -->
              <!-- section title start -->
              <h2 class="section-heading section-heading-light">
                {{ $t('classic') }}
              </h2>
              <!-- section title end -->
              <div class="news-page-img-carousel-content">
                <!-- divider start -->
                <div class="inner-divider-half"></div>
                <!-- divider end -->
                <!-- button start -->
                <div class="all-txt">
                  <p>{{ $t('classic-text') }}</p>
                  <div v-html="$t('classic-list')"></div>
                </div>
                <!-- button end -->
              </div>
            </div>
          </div>
          <!-- works content wrapper 3 end -->
        </div>
        <!-- works IMG carousel item 3 end -->
        <!-- works IMG carousel item 8 start -->
        <div
          class="item-photo works-page-img-carousel-item works-page-img-carousel-item-8 overlay overlay-inverse-dark-45"
        >
          <!-- works content wrapper 8 start -->
          <div class="news-page-img-carousel-wrapper">
            <div class="news-page-img-carousel-text">
              <!-- divider start -->
              <div class="inner-divider-half"></div>
              <!-- divider end -->
              <!-- section title start -->
              <h2 class="section-heading section-heading-light">
                {{ $t('juiced') }}
              </h2>
              <!-- section title end -->
              <div class="news-page-img-carousel-content">
                <!-- divider start -->
                <div class="inner-divider-half"></div>
                <!-- divider end -->
                <!-- button start -->
                <div class="popup-photo-gallery">
                  <p>{{ $t('juiced-text') }}</p>
                  <div v-html="$t('juiced-list')"></div>
                </div>
                <!-- button end -->
              </div>
            </div>
          </div>
          <!-- works content wrapper 8 end -->
        </div>
        <!-- works IMG carousel item 8 end -->
        <!-- works IMG carousel item 2 start -->
        <div
          class="item-photo works-page-img-carousel-item works-page-img-carousel-item-2 overlay overlay-inverse-dark-45"
        >
          <!-- works content wrapper 2 start -->
          <div class="news-page-img-carousel-wrapper">
            <div class="news-page-img-carousel-text">
              <!-- divider start -->
              <div class="inner-divider-half"></div>
              <!-- divider end -->
              <!-- section title start -->
              <h2 class="section-heading section-heading-light">
                {{ $t('exotic') }}
              </h2>
              <!-- section title end -->
              <div class="news-page-img-carousel-content">
                <!-- divider start -->
                <div class="inner-divider-half"></div>
                <!-- divider end -->
                <!-- button start -->
                <div class="all-txt">
                  <p>{{ $t('exotic-text') }}</p>
                  <div v-html="$t('exotic-list')"></div>
                </div>
                <!-- button end -->
              </div>
            </div>
          </div>
          <!-- works content wrapper 2 end -->
        </div>
        <!-- works IMG carousel item 2 end -->
      </div>
      <!-- works IMG OWL carousel end -->
      <!-- owl nav start -->
      <div class="owl-nav owl-nav-custom-works"></div>
      <!-- owl nav end -->
    </div>
    <!-- works IMG carousel end -->
  </div>
</template>
