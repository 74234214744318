<template>
  <div class="section light-mode" id="section4">
    <!-- borders start -->
    <div class="borders-l borders-dark"></div>
    <div class="borders-r borders-dark"></div>
    <!-- borders end -->
    <!-- section title start -->
    <div class="section-title-wrapper">
      <!-- section subtitle start -->
      <h3 class="section-heading"><span>04.</span> {{ $t('nav.contact')}}</h3>
      <!-- section subtitle end -->
    </div>
    <!-- section title end -->
    <!-- section inner start -->
    <div class="section-inner">
      <!-- container start -->
      <div class="container-fluid sections">
        <!-- row start -->
        <div class="row">
          <!-- col start -->
          <div class="col-md-12 col-sm-12 nopadding">
            <!-- slick contact start -->
            <div class="slick-contact">
              <!-- col start -->
              <div class="col-md-12 col-sm-12 nopadding">
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- contact content wrapper start -->
                  <div class="overlay overlay-inverse-dark-35">
                    <!-- contact IMG start -->
                    <div class="about-bg-wrapper">
                      <div class="about-bg contact-bg-1"></div>
                    </div>
                    <!-- contact IMG end -->
                  </div>
                  <!-- contact content wrapper end -->
                </div>
                <!-- col end -->
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- contact content wrapper start -->
                  <div class="all-content-wrapper">
                    <div class="all-content">
                      <div class="all-content-inner">
                        <!-- section subtitle start -->
                        <h2 class="section-subheading section-subheading-light">
                          <span>{{ $t('contacts-as')}}</span>
                        </h2>
                        <!-- section subtitle end -->
                        <!-- divider start -->
                        <div class="inner-divider-half"></div>
                        <!-- divider end -->
                        <!-- section title start -->
                        <h2 class="section-heading section-heading-light">
                          {{ $t('nav.contact')}}
                        </h2>
                        <!-- section title end -->
                        <!-- divider start -->
                        <div class="inner-divider"></div>
                        <!-- divider end -->
                        <!-- TXT start -->
                        <div class="all-txt all-txt-contact">
                          <span
                            class="contact-info-sign ion-ios-location-outline"
                          ></span>
                          <p>{{ $t('address') }}</p>
                        </div>
                        <!-- TXT end -->
                        <!-- divider start -->
                        <div class="inner-divider"></div>
                        <!-- divider end -->
                        <!-- TXT start -->
                        <div class="all-txt">
                          <span
                            class="contact-info-sign contact-info-sign-last ion-ios-email-outline"
                          ></span>
                          <p>
                            <a
                              class="link-effect"
                              href="mailto:abdyshata.nitro@gmail.com"
                              >abdyshata.nitro@gmail.com</a
                            >
                          </p>
                        </div>
                        <!-- TXT end -->
                        <!-- divider start -->
                        <div class="inner-divider"></div>
                        <!-- divider end -->
                        <!-- TXT start -->
                        <div class="all-txt">
                          <span
                            class="contact-info-sign contact-info-sign-last ion-android-call"
                          ></span>
                          <p>
                            <a
                              class="link-effect"
                              href="tel:02122125445"
                              >0212 212 54 45</a
                            > 
                            <a
                              class="link-effect ml-2"
                              href="tel:05076017666"
                              >0507 601 76 66</a
                            >
                          </p>
                        </div>
                        <!-- TXT end -->
                        <!-- divider start -->
                        <div class="inner-divider"></div>
                        <!-- divider end -->
                        <!-- TXT start -->
                        <div class="all-txt">
                          <span
                            class="contact-info-sign contact-info-sign-last ion-android-share-alt"
                          ></span>

                          <ul class="d-flex flex-center">
                            <li>
                              <a
                                class="ion-social-facebook"
                                href="https://www.facebook.com/nitro.kg/"
                                target="_blank"
                              ></a>
                            </li>
                            <li class="mx-3">
                              <a
                                href="https://vm.tiktok.com/ZSe4bvgyA/"
                                target="_blank"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="ionicon"
                                  style="width: 25px; height: 25px"
                                  viewBox="0 0 512 512"
                                >
                                  <title>Logo Tiktok</title>
                                  <path
                                    fill="#ffffff"
                                    d="M412.19 118.66a109.27 109.27 0 01-9.45-5.5 132.87 132.87 0 01-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 01-35.22 55.56 68.8 68.8 0 01-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0121.41 3.39l.1-83.94a153.14 153.14 0 00-118 34.52 161.79 161.79 0 00-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 00115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0027.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61z"
                                  /></svg>
                              </a>
                            </li>
                            <li>
                              <a
                                class="ion-social-instagram"
                                href="https://www.instagram.com/nitro.kgz/"
                                target="_blank"
                              ></a>
                            </li>
                          </ul>
                        </div>
                        <!-- TXT end -->
                      </div>
                    </div>
                  </div>
                  <!-- contact content wrapper end -->
                </div>
                <!-- col end -->
              </div>
              <!-- col end -->
              <!-- col start -->
              <div class="col-md-12 col-sm-12 nopadding">
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- contact content wrapper start -->
                  <div class="overlay overlay-inverse-dark-35">
                    <!-- contact IMG start -->
                    <div class="about-bg-wrapper">
                      <div class="about-bg contact-bg-2"></div>
                    </div>
                    <!-- contact IMG end -->
                  </div>
                  <!-- contact content wrapper end -->
                </div>
                <!-- col end -->
                <!-- col start -->
                <div class="col-md-6 col-sm-6 nopadding">
                  <!-- contact content wrapper start -->
                  <div class="all-content-wrapper">
                    <div class="all-content">
                      <div class="all-content-inner-form">
                        <!-- TXT start -->
                        <div class="all-txt">
                          <!-- contact form start -->
                          <div id="contact-form">
                            <form
                              id="form"
                              method="post"
                              name="send"
                              action="contact.php"
                            >
                              <!-- col start -->
                              <div class="col-sm-12 col-md-12 col-lg-12">
                                <input
                                  class="requiredField name"
                                  id="name"
                                  name="name"
                                  :placeholder="$t('form.name')"
                                  type="text"
                                  required
                                />
                              </div>
                              <!-- col end -->
                              <!-- col start -->
                              <div class="col-sm-12 col-md-12 col-lg-12">
                                <input
                                  class="requiredField email"
                                  id="email"
                                  name="email"
                                  placeholder="E-mail"
                                  type="email"
                                  required
                                />
                              </div>
                              <!-- col end -->
                              <!-- col start -->
                              <div class="col-sm-12 col-md-12 col-lg-12">
                                <input
                                  class="requiredField subject"
                                  id="subject"
                                  name="subject"
                                  :placeholder="$t('form.subject')"
                                  type="text"
                                  required
                                />
                              </div>
                              <!-- col end -->
                              <!-- col start -->
                              <div class="make-space">
                                <textarea
                                  class="requiredField message"
                                  id="message"
                                  name="message"
                                  required
                                  :placeholder="$t('form.message')"
                                ></textarea>
                              </div>
                              <!-- col end -->
                                <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response">
                                <input type="hidden" name="action" value="validate_captcha">
                              <!-- button start -->
                              <div class="contact-form-submit-wrapper">
                                <button
                                  class="the-button the-button-contact the-button-submit"
                                  id="submit"
                                  type="submit"
                                >
                                  <span>{{ $t('form.submit') }}</span>
                                </button>
                              </div>
                              <!-- button end -->
                            </form>
                          </div>
                          <!-- contact form end -->
                        <div class="inner-divider-half"></div>
                        </div>
                        <!-- TXT end -->
                      </div>
                    </div>
                  </div>
                  <!-- contact content wrapper end -->
                        <!-- divider start -->
                        <div class="inner-divider-half"></div>
                        <!-- divider end -->
                </div>
                <!-- col end -->
              </div>
              <!-- col end -->
            </div>
            <!-- slick contact end -->
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
      <!-- container end -->
    </div>
    <!-- section inner end -->
    <!-- slick nav start -->
    <div class="slick-nav-custom-contact"></div>
    <!-- slick nav end -->
  </div>
</template>
