<template>
  <!-- preloader start -->
  <div class="preloader-bg"></div>
  <div id="preloader">
    <div id="preloader-status">
      <div class="preloader-position loader">
        <span></span>
      </div>
    </div>
  </div>
  <!-- preloader end -->
  <!-- logo start -->
  <a class="logo on">
    <div class="logo-img"></div>
  </a>
  <!-- logo end -->
  <Navigation />
  <!-- fullPage start -->
  <div id="fullpage">
    <!-- section home start -->
    <HomeStart />
    <!-- section home end -->
    <!-- section about start -->
    <AboutStart />
    <!-- section about end -->
    <!-- section assortment start -->
    <AssortmentStart />
    <!-- section assortment end -->
    <!-- section geography start -->
    <GeographyStart />
    <!-- section geography end -->
    <!-- section contact start -->
    <ContactStart />
    <!-- section contact end -->
  </div>
  <!-- fullPage end -->
</template>
<script>
import Navigation from "./components/Navigation.vue";
import HomeStart from "./components/HomeStart.vue";
import AboutStart from "./components/AboutStart.vue";
import AssortmentStart from "./components/AssortmentStart.vue";
import GeographyStart from "./components/GeographyStart.vue";
import ContactStart from "./components/ContactStart.vue";

export default {
  name: "App",
  components: {
    Navigation,
    HomeStart,
    AboutStart,
    AssortmentStart,
    GeographyStart,
    ContactStart,
  },
};
</script>
