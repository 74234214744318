<template>
  <div class="section light-mode" id="section3">
    <!-- borders start -->
    <div class="borders-l borders-dark"></div>
    <div class="borders-r borders-dark"></div>
    <!-- borders end -->
    <!-- section title start -->
    <div class="section-title-wrapper">
      <!-- section subtitle start -->
      <h3 class="section-heading"><span>03.</span> {{ $t('nav.geography')}}</h3>
      <!-- section subtitle end -->
    </div>
    <!-- section title end -->
    <!-- section inner start -->
    <div class="section-inner">
      <!-- container start -->
      <div class="container-fluid sections">
        <!-- row start -->
        <div class="row">
          <!-- col start -->
          <div class="col-md-12 col-sm-12 nopadding">
            <!-- col start -->
            <div class="col-md-7 col-sm-7 nopadding">
              <!-- contact content wrapper start -->
              <div class="overlay overlay-inverse-dark-35">
                <!-- contact IMG start -->
                <div
                  class="about-bg contact-bg-1 embed-responsive embed-responsive-16by9"
                >
                  <iframe
                    class="embed-responsive-item"
                    src="https://www.google.com/maps/d/u/1/embed?mid=1CYjYaDYupWXHhMCcNt4N0MzJ4SM9I7_H&ehbc=2E312F"
                  ></iframe>
                </div>
                <!-- contact IMG end -->
              </div>
              <!-- contact content wrapper end -->
            </div>
            <!-- col end -->
            <!-- col start -->
            <div class="col-md-5 col-sm-5 nopadding">
              <!-- contact content wrapper start -->
              <div class="all-content-wrapper">
                <div class="all-content">
                  <div class="all-content-inner">
                    <!-- section subtitle start -->
                    <h2 class="section-subheading section-subheading-light">
                      <span>{{ $t('energy')}}</span>
                    </h2>
                    <!-- section subtitle end -->
                    <!-- divider start -->
                    <div class="inner-divider-half"></div>
                    <!-- divider end -->
                    <!-- section title start -->
                    <h2 class="section-heading section-heading-light">
                      {{ $t('extensive-geography')}}
                    </h2>
                    <!-- section title end -->
                    <!-- divider start -->
                    <div class="inner-divider"></div>
                    <!-- divider end -->
                    <!-- TXT start -->
                    <div class="all-txt all-txt-contact">
                      <span
                        class="contact-info-sign ion-ios-location-outline"
                      ></span>
                      <p>{{ $t('kg')}}</p>
                      <p>{{ $t('kz')}}</p>
                      <p>{{ $t('ru')}}</p>
                      <p>{{ $t('ch')}}</p>
                      <p>{{ $t('tr')}}</p>
                      <p>{{ $t('uz')}}</p>
                    </div>
                    <!-- TXT end -->
                  </div>
                </div>
              </div>
              <!-- contact content wrapper end -->
                        <div class="inner-divider-half"></div>
            </div>
            <!-- col end -->
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
      <!-- container end -->
    </div>
    <!-- section inner end -->
  </div>
</template>
