<template>
    <!-- lang start -->
    <!-- lang button start -->
    <div class="lang-block">
        <button class="lang-block__btn mr-2 p-0" @click="setLocal('tr')">tur</button>
        <button class="lang-block__btn mr-2 p-0" @click="setLocal('en')">eng</button>
        <button class="lang-block__btn p-0" @click="setLocal('ru')">рус</button>
    </div>
    <!-- lang button end -->
    <!-- lang panel left start -->
    <!-- navigation start -->
    <!-- navigation button start -->
    <div class="navigation-fire navigation-icon-wrapper on">
        <div class="navigation-icon" id="navigation-icon">
            <span class="line"></span> <span class="line"></span> <span class="line"></span>
        </div>
    </div>
    <!-- navigation button end -->
    <!-- navigation panel left start -->
    <div class="panel-overlay-from-left">
        <div class="panel-from-left-wrapper">
            <!-- navigation menu IMG wrapper start -->
            <div class="navigation-menu-img-wrapper">
                <!-- navigation menu IMG start -->
                <div class="navigation-menu-img overlay overlay-inverse-dark-35">
                    <div class="menu-img active" data-bg="/assets/images/home_m.jpeg" data-ref="menu-1"></div>
                    <div class="menu-img" data-bg="/assets/images/IMG_6623.jpeg" data-ref="menu-2"></div>
                    <div class="menu-img" data-bg="/assets/images/564a9858.jpeg" data-ref="menu-3"></div>
                    <div class="menu-img" data-bg="/assets/images/img_7395.jpeg" data-ref="menu-4"></div>
                    <div class="menu-img" data-bg="/assets/images/564a8704.jpeg" data-ref="menu-5"></div>
                    <!-- <div class="menu-img" data-bg="/assets/images/sti5205.jpeg" data-ref="menu-6"></div> -->
                </div>
                <!-- navigation menu IMG end -->
            </div>
            <!-- navigation menu IMG wrapper end -->
        </div>
    </div>
    <!-- navigation panel left end -->
    <!-- navigation panel right start -->
    <div class="panel-from-right">
        <div class="panel-from-right-wrapper">
            <!-- navigation links start -->
            <nav class="navigation-menu">
                <!-- center container start -->
                <div class="center-container-menu">
                    <!-- center block start -->
                    <div class="center-block-menu">
                        <ul class="menu brackets">
                            <li>
                                <a data-ref="menu-1" href="#home">{{ $t('nav.home') }}</a>
                            </li>
                            <li>
                                <a data-ref="menu-2" href="#about">{{ $t('nav.about-us') }}</a>
                            </li>
                            <li>
                                <a data-ref="menu-3" href="#assortment">{{ $t('nav.assortment') }}</a>
                            </li>
                            <li>
                                <a data-ref="menu-4" href="#geography">{{ $t('nav.geography') }}</a>
                            </li>
                            <li class="last">
                                <a data-ref="menu-5" href="#contact">{{ $t('nav.contact') }}</a>
                            </li>
                        </ul>
                    </div>
                    <!-- center block end -->
                </div>
                <!-- center container end -->
            </nav>
            <!-- navigation links end -->
        </div>
    </div>
    <!-- navigation panel right end -->
    <!-- navigation end -->
</template>

<script>


export default {
    
    methods: {
        setLocal(locale) {
            this.$i18n.locale = locale
        }
    }
};

</script>